/*
SPDX-FileCopyrightText: © 2024 DYB Soft Corporation. <dybsoft1118@naver.com>
SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import { useUtripContextApi, setOpenDialogPopup } from '../context';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// ====================== MUI Components  =========================
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

function GlobalAlertDialog() {
  const [controller, dispatch] = useUtripContextApi();
  const { openDialogpopup } = controller;
  const navigate = useNavigate();

  const handleCloseNoAction = () => {
    setOpenDialogPopup(dispatch, { modalType: openDialogpopup.modalType, showYn: false });
  };

  const handleClose = () => {
    setOpenDialogPopup(dispatch, {
      modalType: openDialogpopup.modalType,
      showYn: false,
    });
    if (openDialogpopup.modalType == 'login') navigate('/login');
    if (openDialogpopup.redirect) {
      navigate(openDialogpopup.redirect);
    }
  };

  const handleCloseWithReload = () => {
    location.reload();
    setOpenDialogPopup(dispatch, { modalType: openDialogpopup.modalType, showYn: false });
  };

  const getModalContent = (modalType, content) => {
    const modalMessages = {
      sucCustom: content || '',
      failCustom: content || '',
      admin: '접근 권한이 없습니다.<br>관리자만 접근할 수 있습니다.',
      login: '로그인이 필요한 서비스입니다.<br>로그인 페이지로 이동하시겠습니까?',
      failure: '업데이트가 되지 않았습니다. <br> 확인후 다시 시도해주십시오',
      passport_success: content || '한국발행 여권이라면 외교부 진위여부로 점검하시고,<br>해외발행 여권이라면 여권과 대조하여 확인하십시오.',
      notice: content || '',
      default: '성공적으로 작업을 수행했습니다.',
    };

    return modalMessages[modalType] || modalMessages.default;
  };
  const modalContent = getModalContent(openDialogpopup.modalType, openDialogpopup.content);
  return (
    <Dialog
      open={openDialogpopup.showYn}
      onClick={openDialogpopup.reload == true ? handleCloseWithReload : handleClose}
      sx={{ '& .MuiDialog-paper': { minWidth: 400, textAlign: 'center' } }}
    >
      <DialogTitle>
        {openDialogpopup.modalType == 'sucCustom'
          ? openDialogpopup.title || '작업 완료'
          : openDialogpopup.modalType == 'failCustom'
            ? openDialogpopup.title || '작업 실패'
            : openDialogpopup.modalType == 'admin'
              ? '관리자용 화면'
              : openDialogpopup.modalType == 'login'
                ? '로그인 필요'
                : openDialogpopup.modalType == 'failure'
                  ? '작업 실패'
                  : openDialogpopup.modalType == 'notice'
                    ? '유트립 공지'
                    : '작업 완료'}
      </DialogTitle>
      <DialogContent>
        {openDialogpopup.imageSrc && (
          <img
            src={openDialogpopup.imageSrc}
            alt="팝업 이미지"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              marginBottom: '5px',
            }}
          />
        )}
        <DialogContentText
          color="black"
          sx={{
            textAlign: openDialogpopup.modalType === 'sucCustom' ? 'left' : 'center', // 조건에 따라 정렬 변경
          }}
          dangerouslySetInnerHTML={{
            __html: modalContent
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={openDialogpopup.reload == true ? handleCloseWithReload : handleClose}
          color="error"
        >
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default GlobalAlertDialog;
